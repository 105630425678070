<template>
    <div>
        <div style="width: 100%; margin: 0 auto;">
            <el-carousel trigger="click" height="747px">
                <el-carousel-item v-for="(item, index) in tableList" :key="index">
                    <img style="max-width: 100%; max-height: 100%;" :src="item" @load="calculateImageSize" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="jie">
            <img src="https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image5572374070604601559.jpg"
                alt="">
        </div>
        <div :class="scrollFn">当前案例</div>
        <div style="width: 96%;margin: 0 auto;">
            <el-carousel :interval="4000" type="card" height="400px">
                <el-carousel-item v-for="item, index in tableList1" :key="index">
                    <img style="width: 100%; height: 100%;" :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="bottom">
            <div class="shang">
                <div class="item1">
                    <div class="hang1">站内导航</div>
                    <div class="hang2">首页</div>
                    <div class="hang2">业务范畴</div>
                    <div class="hang2">关于我们</div>
                </div>
                <div class="item1">
                    <div class="hang1">联系我们</div>
                    <div class="hang2">19941523480（联系电话）</div>
                    <div class="hang2">邮箱：colin@yt202.com</div>
                    <div class="hang2">地址：南京市栖霞区尧化街道仙尧路2号2号楼</div>
                </div>
                <div class="item1">
                    <div class="matu">
                        <img src="../../assets/ma.png" alt="">
                    </div>
                    <div style="text-align: center; color: #FFFFFF;font-size: 18px;margin-top: 10px;">扫码了解更多</div>
                </div>
            </div>
            <div class="xia">
                <div>Copyright 2024 All Rights Reserved 苏ICP备2024063644号-1</div>
                <div>版权所有 南京易添信息技术有限公司</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableList: [
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image7162166137699691387.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image4360405905852231118.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image5428354730205953510.jpg',
            ],
            tableList1: [
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image2244271661981601054.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image3470109786539113605.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image8604385988301976084.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image467765808322497535.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image7504933473510877763.jpg',
                'https://yitian-oss.oss-cn-shanghai.aliyuncs.com/Download/uploaded-image5589221221897325304.jpg'
            ],
            scrollEffect: false,
            carouselHeight: 847 // 默认高度
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (window.scrollY > 700) {
                this.scrollEffect = true;
            } else {
                this.scrollEffect = false;
            }
        },
        calculateImageSize(index) {
            const image = event.target;
            const aspectRatio = image.naturalWidth / image.naturalHeight;

            if (aspectRatio > 1) {
                image.style.width = '100%';
                image.style.height = 'auto';
            } else {
                image.style.width = 'auto';
                image.style.height = '100%';
            }
        }
    },
    computed: {
        scrollFn() {
            if (this.scrollEffect == false) {
                return 'dang'
            } else {
                return 'dang scroll-up'
            }
        }
    }
}
</script>

<style lang="less">
.jie {
    width: 94%;
    height: 600px;
    margin: 0 auto;
    margin-top: 20px;
}

.jie>img {
    width: 100%;
    height: 100%;
}

.dang {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.bottom {
    width: 100%;
    height: 250px;
    margin-top: 20px;
    background-color: #172e46;

}

.shang {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .item1 {
        .hang1 {
            font-size: 20px;
            font-weight: bold;
            line-height: 40px;
            letter-spacing: 0.02em;
            color: #FFFFFF;
        }

        .hang2 {
            font-size: 15px;
            font-weight: normal;
            line-height: 40px;
            letter-spacing: 0.02em;
            color: #9E9E9E;
        }

        .matu {
            width: 120px;
            height: 120px;
        }

        .matu>img {
            width: 100%;
            height: 100%;
        }
    }
}

.xia {
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;
}

/* 定义向上滚动动画 */
@keyframes scrollAnimation {
    from {
        transform: translateY(100px);
    }

    to {
        transform: translateY(0);
        /* 向上滚动 20px */
    }
}

.scroll-up {
    animation: scrollAnimation 0.3s ease forwards;
    visibility: visible;
    animation-duration: 1s;
    animation-delay: 0s;
}
</style>