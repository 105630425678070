<template>
  <div class="home">
    <div class="top">
      <div class="left">
        <div class="tu">
          <img src="../assets/logo.png" alt="">
        </div>
        易添信息
      </div>
      <div class="right">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#172E46" text-color="#fff" active-text-color="#fff">
          <el-menu-item index="1">首页</el-menu-item>
          <el-menu-item index="2">业务范畴</el-menu-item>
          <el-menu-item index="3">关于我们</el-menu-item>
        </el-menu>
        <div class="phone">
          <div class="zuophone">
            <img src="../assets/phone.png" alt="">
          </div>
          19941523480
        </div>
      </div>
    </div>
    <firstPage v-if="activeIndex == '1'"></firstPage>
    <businessPage v-else-if="activeIndex == '2'"></businessPage>
    <concerPage v-else-if="activeIndex == '3'"></concerPage>
  </div>
</template>

<script>
import firstPage from './components/index'
import businessPage from './components/business'
import concerPage from './components/concer'
export default {
  name: 'HomeView',
  components: {
    firstPage,
    businessPage,
    concerPage
  },
  data() {
    return {
      activeIndex: '1'
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.activeIndex = key
    }
  }
}
</script>

<style lang="less">
.top {
  display: flex;
  width: 100%;
  height: 64px;
  background: #172E46;
  justify-content: space-between;
}

.left {
  display: flex;
  align-items: center;
  color: #fff;
  margin-left: 40px;
  font-size: 18px;

  .tu {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }

  .tu>img {
    width: 100%;
    height: 100%;
  }
}

.right {
  display: flex;
}

.el-menu.el-menu--horizontal {
  border-bottom: 0px !important;
}

.phone {
  display: flex;
  align-items: center;
  color: #fff;
  margin-right: 40px;
  margin-left: 40px;
  font-size: 14px;

  .zuophone {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .zuophone>img {
    width: 100%;
    height: 100%;
  }
}
</style>
